import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import labelsData from "../data/labels";

const Labels = () => {
  const [labels, setLabels] = useState(labelsData); // Stato per le etichette
  const [newLabel, setNewLabel] = useState(""); // Stato per la nuova etichetta
  const navigate = useNavigate(); // Hook per navigare alle pagine dinamiche

  // Funzione per aggiungere una nuova etichetta
  const addLabel = (e) => {
    e.preventDefault();
    if (newLabel.trim()) {
      setLabels([...labels, newLabel.trim()]);
      setNewLabel("");
    }
  };

  // Funzione per gestire la pressione di una card
  const handleCardClick = (label) => {
    navigate(`/labels/${label}`);
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Gestione Etichette</h1>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {/* Lista di card */}
        {Object.keys(labels).map((label, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "20px",
              cursor: "pointer",
              backgroundColor: "#f9f9f9",
            }}
            onClick={() => handleCardClick(label)} // Naviga alla pagina dell'etichetta
          >
            <h3>{labels[label].wineName}</h3>
          </div>
        ))}
      </div>

      {/* Form per aggiungere nuove etichette */}
      <form onSubmit={addLabel} style={{ marginTop: "20px" }}>
        <input
          type="text"
          value={newLabel}
          onChange={(e) => setNewLabel(e.target.value)}
          placeholder="Nuova etichetta"
          style={{
            padding: "10px",
            fontSize: "16px",
            marginRight: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        />
        <button
          type="submit"
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "4px",
            border: "none",
            backgroundColor: "#4caf50",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          Aggiungi
        </button>
      </form>
    </div>
  );
};

export default Labels;
