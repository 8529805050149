import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Label from "../screens/Label";
import NotFound from "../screens/NotFound";
import Labels from "../screens/Labels";
import Home from "../screens/Home";


const MainRouter = () => {
    return (
      <Router>
        <Routes>
        
          <Route path="/" element={<Home />} />
          <Route path="/admin/labels" element={<Labels />} />
          {/* Route dinamica per le etichette */}
          <Route path="/labels/:label" element={<Label />} />
  
          {/* Pagina 404 per route non valide */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    );
  };

export default MainRouter;